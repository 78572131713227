import { Constants } from '@/constants/Constants';
import { CompositeFilterDescriptor, GroupDescriptor, toODataString } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column, GridToolbar, getSelectedState } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useApplicationState from '../../../../hooks/useApplicationState';
import { envService } from '../../../../services/EnvService';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useIsInRole from '../../../../hooks/useIsInRole';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import useOrderService from '../../../../hooks/useOrderService';
import {
  ErrorViewModel,
  GetOrderName,
  ICompositeFilterDescriptor,
  IGridSort,
  IPagedResultViewModel,
  Order,
  OrderStatus,
  Product,
  Shop,
  isErrorViewModel,
} from '../../../../models';
import injectCustomOData from '../../../../utils/injectCustomOData';
import removePaginationFilters from '../../../../utils/removeODataPagination';
import ActionButtons from '../../../common/action-buttons/ActionButtons';
import DeleteButton from '../../../common/delete-button/DeleteButton';
import EditButton from '../../../common/edit-button/EditButton';
import {
  DropdownFilterCell,
  filterDropDownUndefinedValues,
  filterDropDownValues,
  getFilterValue,
} from '../../../common/kendo-filter-dropdown/DropDownFilterCell';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import PrintButton from '../../../common/print-button/PrintButton';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import EditOrderForm from '../edit-form/EditOrderForm';
import StatusChangeNotificationForm from '../order-update-notification/StatusChangeNotificationForm';
import EditOrderContext from '../OrderContext';
import { filterOperators } from './orderFilterOperators';
import './ViewOrders.scss';
import retrieveQueryParamValue from '../../../../utils/queryParams';

function ViewOrders(): JSX.Element | null {
  /**
   * Stores a value indicating if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  /**
   * Stores a value indicating if the user is read only or not.
   */
  const isReadOnly = useIsReadOnly();

  /**
   * Toggles the display of the order status/comments update modal window.
   */
  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * Toggles the display of the order status change notification window.
   */
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  /**
   * Sets the product that has to be edited in the modal window.
   */
  const [editingOrder, setEditingOrder] = useState<Order | undefined>();

  /**
   * Required to display the toast notification.
   */
  const { errors, setErrors } = useErrorHandling();

  /**
   * The hook to always have the updated language.
   */
  const language = useCurrentLanguage();

  /**
   * Hook to retrieve the locale.
   */
  const currentLocale = useCurrentLocale();

  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * An instance of the order service.
   */
  const orderService = useOrderService();

  /**
   * The switch used to display the spinning loader.
   */
  const [loadingOrders, setLoadingOrders] = useState<boolean>(true);

  /**
   * Indicates if the first loading of the orders is going on. It is used to display the loader
   * only during the initial load, other wise it would spin each time the user types a character
   * in any column filter, thus initiating a server-side call.
   */
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  /**
   * The application state. Specific for the shops.
   */
  const { selectedShop } = useApplicationState();

  /**
   * The application state. Specific for the shops.
   */
  const { selectedProduct } = useApplicationState();

  /**
   * The default sorting of the grid.
   */
  const defaultSort: IGridSort[] = useMemo(
    () => [
      { field: 'orderDate', dir: 'desc' },
      { field: 'id', dir: 'desc' },
    ],
    [],
  );

  /**
   * The default filter of the grid.
   */
  const defaultFilter: CompositeFilterDescriptor = useMemo(() => {
    return { logic: 'and', filters: [] };
  }, undefined);

  /**
   * The data displayed in the grid, that is the data retrieved from the server after applying the filters.
   */
  const [gridData, setGridData] = useState<IPagedResultViewModel<Order>>({
    data: [],
    total: 0,
    sort: defaultSort,
  });

  /**
   * The total count of grid items after applying the filtering but before pagination.
   */
  const [gridDataTotal, setGridDataTotal] = useState<number>();

  /**
   * Stores the status of a selected order when the edit button is clicked. It is then sent to the
   * API that sends the notification email so that the email can be sent only of the old status is new
   * and the updated status is executing. It is part of the OrderContext.
   */
  const [previousStatus, setPreviousStatus] = useState<OrderStatus | undefined | null>();

  /**
   * Stores the status of a selected order when it is changed. It is used to display the send notification
   * window when the status changes from new to executing. It is part of the OrderContext.
   */
  const [newStatus, setNewStatus] = useState<OrderStatus | undefined | null>();

  /**
   * The current paging and sorting information.
   */
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    sort: defaultSort,
    filter: defaultFilter,
  });

  const [defaultOrderStatusItem] = useState({ id: undefined, text: t('OrderStatus.Any') });

  /**
   * Invokes the function that returns the URL of the printed document order.
   */
  const printOrder = useCallback(
    async (order: Order) => {
      try {
        const orderUrl = await orderService.getOrderPdfById(order, language);
        const a = document.createElement('a');
        document.body.appendChild(a);

        a.href = orderUrl;
        a.target = '_blank';
        a.download = `Agrola-Order-${order.orderNumber}.pdf`;
        a.click();
        a.remove();
      } catch (error) {
        if (isErrorViewModel(error)) {
          const errorModel = error as ErrorViewModel;

          setErrors([
            ...errors,
            {
              title: 'Error.Title.UnableToGeneratePdf',
              statusCode: errorModel.statusCode,
              value: {
                statusCode: errorModel.value?.statusCode ?? '',
                description: errorModel.value?.description ?? '',
              },
            },
          ]);
        } else {
          console.error(error);
        }
      } finally {
      }
    },
    [orderService, setErrors, errors, language],
  );

  /**
   * Unions the odata query computed by the Kendo grid with the global filters.
   * @param shop the shops selected in the global filter.
   * @param product the product selected in the global filter.
   * @param odataString the odata query computed by the Kendo grid
   * @returns an odata query string that includes both the filter computed by the Kendo grid and the global filters.
   */
  const addFilters = (
    shop: Shop | null,
    product: Product | null,
    queryParams: { [key: string]: string },
    odataString: string,
  ): string => {
    let customFilter = '';

    if (shop) {
      customFilter += `shop/name eq '${shop.name}'`;
    }

    if (product) {
      if (customFilter != '') {
        customFilter += ' and ';
      }
      customFilter += 'productId eq ' + product.id;
    }

    if (Object.keys(queryParams)) {
      if (queryParams['productType']) {
        if (customFilter != '') {
          customFilter += ' and ';
        }

        customFilter += 'product/productTypeId eq ' + queryParams['productType'];
      }

      if (queryParams['orderStatus']) {
        if (customFilter != '') {
          customFilter += ' and ';
        }

        const value = retrieveOrderStatusItemByValue(retrieveQueryParamValue('orderStatus'));
        setSelectedOrderStatusItem(value);
        customFilter += "status eq '" + OrderStatus[value.id ?? 0] + "'";
      }
    }

    if (shop || product || selectedOrderStatusItem.id !== undefined || Object.keys(queryParams).length) {
      odataString = injectCustomOData(customFilter, odataString);
    }

    return odataString;
  };

  /**
   * Replaces the filter on the orderNumber column with a filter on both the externalOrderNumber and Id columns,
   * that hold respectively the order number for the "old" orders and that of the "new" orders.
   */
  const fixOrderNumberFilter = (odataString: string) => {
    let regex = /contains\(orderNumber\s*,\s*'(\w+)'/;

    let matches = odataString.match(regex);

    let updatedOdataString = odataString;

    if (matches) {
      updatedOdataString = odataString.replace(
        regex,
        "((contains(cast(id,'Edm.String'),'" +
          matches[1] +
          "') and externalOrderNumber eq null) or contains(externalOrderNumber,'" +
          matches[1] +
          "')",
      );
    }

    regex = /startswith\(orderNumber\s*,\s*'(\w+)'/;

    matches = odataString.match(regex);

    if (matches) {
      updatedOdataString = odataString.replace(
        regex,
        "((startswith(cast(id,'Edm.String'), '" +
          matches[1] +
          "') and externalOrderNumber eq null) or startswith(externalOrderNumber, '" +
          matches[1] +
          "')",
      );
    }

    regex = /endswith\(orderNumber\s*,\s*'(\w+)'/;

    matches = odataString.match(regex);

    if (matches) {
      updatedOdataString = odataString.replace(
        regex,
        "((endswith(cast(id,'Edm.String'), '" +
          matches[1] +
          "') and externalOrderNumber eq null) or endswith(externalOrderNumber, '" +
          matches[1] +
          "')",
      );
    }

    regex = /orderNumber\s+eq\s+'(\w+)'/;

    matches = odataString.match(regex);

    if (matches) {
      updatedOdataString = odataString.replace(
        regex,
        "((cast(id,'Edm.String') eq '" +
          matches[1] +
          "' and externalOrderNumber eq null) or externalOrderNumber eq '" +
          matches[1] +
          "')",
      );
    }

    regex = /orderNumber\s+ne\s+'(\w+)'/;

    matches = odataString.match(regex);

    if (matches) {
      updatedOdataString = odataString.replace(
        regex,
        "((cast(id,'Edm.String') ne '" +
          matches[1] +
          "' and externalOrderNumber eq null) or externalOrderNumber ne '" +
          matches[1] +
          "')",
      );
    }

    // Finally we make sure that all remaining instances of orderNumber are replaced.
    // This is necessary when the table is sorted by the orderData column.
    updatedOdataString = updatedOdataString.replaceAll('orderNumber', 'id');

    return updatedOdataString;
  };

  /**
   * Applies date and time transformation and localization to the order status field.
   */
  const parseData = useCallback(
    (data: Order[]) => {
      data.forEach((o) => {
        o.orderDate = o.orderDate !== null ? new Date(new Date(o.orderDate).toDateString()) : null;
        o.statusName = GetOrderName(t, o.status);

        if (!o.fullInvoiceAddress) {
          o.fullInvoiceAddress = '';
        }

        if (!o.confirmationSent && !o.fullInvoiceAddress?.includes(t('OrdersView.ConfirmationNotSent'))) {
          o.fullInvoiceAddress += `<br><span class='text-danger'>${t('OrdersView.ConfirmationNotSent')}</span>`;
        }
      });
    },
    [t],
  );

  const retrieveOrderStatusItemByValue = (orderStatusValue: any) => {
    if (Object.keys(OrderStatus).includes(orderStatusValue)) {
      const selectedOrderStatusId = isNaN(orderStatusValue)
        ? Number(OrderStatus[orderStatusValue])
        : Number(orderStatusValue);
      return { id: selectedOrderStatusId, text: GetOrderName(t, selectedOrderStatusId) };
    }
    return defaultOrderStatusItem;
  };

  const [selectedOrderStatusItem, setSelectedOrderStatusItem] = useState(() => {
    return retrieveOrderStatusItemByValue(retrieveQueryParamValue('orderStatus'));
  });

  /**
   * Retrieves the data from the server on the initial load.
   */
  useEffect(() => {
    const waiter = setTimeout(() => {
      setLoadingOrders(true);
      let odataString: string = toODataString(dataState);
      odataString = addFilters(
        selectedShop,
        selectedProduct,
        Object.fromEntries(new URLSearchParams(window.location.search).entries()),
        odataString,
      );
      odataString = fixOrderNumberFilter(odataString);
      orderService
        .getAllPaged(odataString)
        .then((data) => {
          parseData(data.data);
          setGridData({ data: data.data, total: data.total, sort: defaultSort });
          setGridDataTotal(data.total);
        })
        .catch((error) => {
          setErrors([...errors, error as ErrorViewModel]);
        })
        .finally(() => {
          setLoadingOrders(false);
          setInitialLoading(false); // this state is set to true only when the component is loaded the first time.
        });
      odataString = removePaginationFilters(odataString);
    }, 300);

    return () => clearTimeout(waiter);
  }, [orderService, dataState, selectedShop, selectedProduct, parseData, defaultSort, setErrors, errors]);

  /**
   * Applies the transformations to the data (the localization of Role and ReadOnly,
   * and the formatting of the data).
   */
  useMemo(() => {
    return parseData(gridData.data);
  }, [parseData, gridData.data]);

  /**
   * Updates the data state.
   * @param e the new data state.
   */
  const dataStateChange = (e: any) => {
    e = filterDropDownValues(e, 'statusName', 'status');
    e = filterDropDownUndefinedValues(e);
    setSelectedOrderStatusItem(retrieveOrderStatusItemByValue(getFilterValue(e, 'statusName', 'status')));
    setDataState(e.dataState);
  };

  const OrderStatusFilterCell = useCallback(
    (props: any) => (
      <DropdownFilterCell
        {...props}
        data={[
          { id: OrderStatus[OrderStatus.Open], text: t('OrderStatus.Open') },
          { id: OrderStatus[OrderStatus.Delivering], text: t('OrderStatus.Delivering') },
          { id: OrderStatus[OrderStatus.Delivered], text: t('OrderStatus.Delivered') },
          { id: OrderStatus[OrderStatus.Canceled], text: t('OrderStatus.Canceled') },
          { id: OrderStatus[OrderStatus.Failed], text: t('OrderStatus.Failed') },
        ]}
        value={selectedOrderStatusItem}
        textField="text"
        dataItemKey="id"
        defaultItem={defaultOrderStatusItem}
      />
    ),
    [t, selectedOrderStatusItem],
  );

  const downloadOrders = useCallback(async () => {
    try {
      const odataString = buildODataFilter();
      const result = await orderService.getCSV(odataString);
      const blob = new Blob([result], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'Orders.csv');
    } catch (error) {
      console.error(error);
      setErrors([...errors, error as ErrorViewModel]);
    }

    function buildODataFilter() {
      let odataString: string = toODataString(dataState);
      odataString = addFilters(
        selectedShop,
        selectedProduct,
        Object.fromEntries(new URLSearchParams(window.location.search).entries()),
        odataString,
      );
      odataString = fixOrderNumberFilter(odataString);
      odataString = removePaginationFilters(odataString);
      return odataString;
    }
  }, [orderService, setErrors, errors, dataState, selectedShop, selectedProduct]);

  /**
   * Displays a cell that contains an HTML value.
   */
  const cellWithHtml = (props: GridCellProps) => {
    const field = props.field || '';
    return <td dangerouslySetInnerHTML={{ __html: props.dataItem[field] }}></td>;
  };

  const rowRender = (trElement: any, props: { dataItem: Order }) => {
    const white = {
      backgroundColor: 'rgb(0, 0, 0, 0)',
    };
    const red = {
      backgroundColor: 'rgb(243, 23, 0, 0.32)',
    };
    const trProps = {
      style: props.dataItem.status === OrderStatus.Failed ? red : white,
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps,
      },
      trElement.props.children,
    );
  };

  if (loadingOrders && initialLoading) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper
      title={t('OrdersView.ManageOrders')}
      addButton={
        isAdministrator && !isReadOnly ? (
          <button
            type="button"
            className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold"
            onClick={() => alert('Not implemented yet!')}
          >
            <span style={{ fontSize: 36, lineHeight: 0.65 }}>+</span>
          </button>
        ) : null
      }
    >
      <EditOrderContext.Provider value={{ newStatus, setNewStatus, previousStatus, setPreviousStatus }}>
        <LocalizationProvider language={language}>
          <IntlProvider locale={currentLocale}>
            <EditOrderForm
              show={showModal}
              order={editingOrder}
              size="xl"
              modalTitle={
                !!editingOrder ? (
                  <Trans i18nKey="EditOrderModal.EditOrderTitle" t={t}>
                    {{ order: `${editingOrder?.invoiceName} ${editingOrder?.invoiceSurname}` }}
                  </Trans>
                ) : null
              }
              handleClose={() => {
                setShowModal(false);
              }}
              handleSave={() => {
                setShowModal(false);
                if (previousStatus == OrderStatus.Open && newStatus == OrderStatus.Delivering) {
                  setShowNotificationModal(true);
                } else {
                  setDataState({
                    take: dataState.take,
                    skip: dataState.skip,
                    sort: dataState.sort,
                    filter: dataState.filter,
                  }); // setting the data state will trigger a data refresh
                }
              }}
            />
            <StatusChangeNotificationForm
              show={showNotificationModal}
              order={editingOrder}
              modalTitle={
                !!editingOrder ? (
                  <Trans i18nKey="EditOrderModal.EditOrderTitle" t={t}>
                    {{ order: `${editingOrder?.invoiceName} ${editingOrder?.invoiceSurname}` }}
                  </Trans>
                ) : null
              }
              handleClose={() => {
                setShowNotificationModal(false);
                setDataState({
                  take: dataState.take,
                  skip: dataState.skip,
                  sort: dataState.sort,
                  filter: dataState.filter,
                }); // setting the data state will trigger a data refresh
              }}
              handleSave={() => {
                setShowNotificationModal(false);
                setDataState({
                  take: dataState.take,
                  skip: dataState.skip,
                  sort: dataState.sort,
                  filter: dataState.filter,
                }); // setting the data state will trigger a data refresh
              }}
            />
            <Grid
              style={{ margin: 20 }}
              data={gridData.data}
              total={gridDataTotal}
              sortable={true}
              filterable={true}
              filterOperators={filterOperators}
              pageable={{ buttonCount: 4, pageSizes: true }}
              resizable={true}
              {...dataState}
              onDataStateChange={dataStateChange}
              rowRender={rowRender}
            >
              <GridToolbar>
                <button
                  className="btn btn-primary btn-sm"
                  type="button"
                  onClick={() => {
                    downloadOrders();
                  }}
                >
                  <span>{t('OrdersView.ExportOrders')}</span>
                </button>
              </GridToolbar>
              <Column field="fullInvoiceAddress" title={t('OrdersView.ColumnName')} cell={cellWithHtml} />
              <Column field="orderDate" title={t('OrdersView.ColumnOrderDate')} filter="date" format="{0:d}" />
              <Column field="shop.name" title={t('OrdersView.ColumnShop')} />
              <Column field="orderNumber" title={t('OrdersView.ColumnOrderNumber')} />
              <Column field="email" title={t('OrdersView.ColumnEmail')} />
              <Column
                field="statusName"
                title={t('OrdersView.ColumnStatus')}
                filterCell={OrderStatusFilterCell}
                sortable={false}
              />
              <Column field="portal.name" title={t('OrdersView.ColumnPortal')} />
              <Column
                title={t('Views.ColumnActions')}
                width={250}
                filterable={false}
                cell={(props) => {
                  return (
                    <td>
                      <ActionButtons>
                        {(isAdministrator && !isReadOnly) || !isAdministrator ? (
                          <>
                            <EditButton
                              onClick={() => {
                                setEditingOrder(props.dataItem);
                                setShowModal(true);
                              }}
                            />
                            <PrintButton onClick={() => printOrder(props.dataItem)} />
                          </>
                        ) : null}
                        {isAdministrator && envService.env === 'LOCAL' && (
                          <button
                            className="btn btn-primary btn-sm mx-1"
                            type="button"
                            onClick={() => {
                              setEditingOrder(props.dataItem);
                              setShowNotificationModal(true);
                            }}
                          >
                            <span>See Notification Modal (dev)</span>
                          </button>
                        )}
                        {isAdministrator && !isReadOnly ? (
                          <DeleteButton onClick={() => alert('Not implemented yet.')} />
                        ) : null}
                      </ActionButtons>
                    </td>
                  );
                }}
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </EditOrderContext.Provider>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewOrders);
